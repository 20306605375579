export enum ActionType {
  MOVE_CELL = "move_cell",
  UPDATE_CELL = "update_cell",
  DELETE_CELL = "delete_cell",
  INSERT_CELL_AFTER = "insert_cell_after",
  BUNDLE_START = "bundle_start",
  BUNDLE_COMPLETE = "bundle_complete",
  FETCH_CELLS = "fetch_cells",
  SAVE_CELLS = "save_cells",
  ADD_TUTORIAL = "add_tutorial",
}
