import "./code-editor.css";
import "./syntax.css";
import Editor, { Monaco } from "@monaco-editor/react";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import React, { useEffect, useRef, useState } from "react";
import prettier from "prettier";
import parser from "prettier/parser-babel";
// import { parse } from "@babel/parser";
// import traverse from "@babel/traverse";
// import MonacoJSXHighlighter from "monaco-jsx-highlighter";

interface CodeEditorProps {
  defaultValue: string;
  onChange(value: string): void;
  height: number;
  setHeight: React.Dispatch<React.SetStateAction<number>>;
}

function CodeEditor({
  defaultValue,
  onChange,
  height,
  setHeight,
}: CodeEditorProps) {
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  // const height = useContext(HeightContext);
  // const monacoJSXHighlighterRef = useRef<any>();
  const handleEditorChange = (
    value: string | undefined,
    event: monaco.editor.IModelContentChangedEvent
  ) => {
    if (value || value === "") {
      onChange(value);
    }
  };

  // const markdownModel = monaco.editor.createModel("", "markdown");
  // const styleModel = monaco.editor.createModel("", "css");
  const handleEditorDidMount = (
    editor: monaco.editor.IStandaloneCodeEditor,
    monaco: Monaco
  ) => {
    editorRef.current = editor;
    editor.updateOptions({ scrollBeyondLastLine: false });
    setIsMounted(true);
  };
  const updateHeight = () => {
    if (editorRef.current?.getContentHeight()) {
      const contentHeight = Math.min(
        500,
        editorRef.current?.getContentHeight() + 100
      );
      setHeight(contentHeight);
    }
  };
  useEffect(() => {
    if (isMounted) {
      updateHeight();
      editorRef.current?.onDidContentSizeChange(updateHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);
  const onClickFormat = () => {
    const unformatted = editorRef?.current?.getValue();
    if (unformatted) {
      const formatted = prettier
        .format(unformatted, {
          parser: "babel",
          plugins: [parser],
          useTabs: false,
          semi: true,
          singleQuote: true,
        })
        .replace(/\n$/, "");
      editorRef?.current?.setValue(formatted);
    }
  };
  return (
    <div className="editor-wrapper">
      <button
        type="button"
        className="button button-format is-primary is-small"
        onClick={onClickFormat}
      >
        Format
      </button>
      <Editor
        height="100%"
        width="100%"
        onChange={handleEditorChange}
        onMount={handleEditorDidMount}
        defaultLanguage="javascript"
        theme="vs-dark"
        options={{
          wordWrap: "on",
          minimap: { enabled: false },
          showUnused: false,
          folding: false,
          lineNumbersMinChars: 3,
          fontSize: 16,
          scrollBeyondLastLine: false,
          automaticLayout: true,
        }}
        defaultValue={defaultValue}
      />
    </div>
  );
}
export default CodeEditor;

// ! Edited from here so uncomment above this to uncomment
